<template>
  <div class="inviteExternalContact">
    <background />
    <img
      v-if="inviteMsg.userHeadImg"
      :src="getImgUrl(inviteMsg.userHeadImg)"
      alt
      class="headerImg"
    />
    <img v-else src="@/assets/imgs/person.svg" alt class="headerImg" />
    <div class="title">{{ inviteMsg.name }}邀请你加入</div>
    <div class="des">{{ inviteMsg.comName }}</div>

    <div class="content">
      <div class="label">发送验证申请，等待管理员通过</div>
      <div class="input">
        <van-field
          v-model="params.remarks"
          placeholder="最多输入20字"
          maxlength="20"
        />
      </div>
      <div class="addPeople" @click="addPeople">申请加入企业</div>
      <div class="addPeople-sub">
        当前登录手机号：{{ userInfo.telephone }}
        <span v-if="userInfo.creditStatus !== 1">（未实名）</span>
        <span v-else>（{{ userInfo.name }}）</span>
      </div>
      <div class="changeAccount" @click="changeAcount">我要切换账号</div>
    </div>
  </div>
</template>
<script>
  import Api from '@/api/address'
  import { mapState } from 'vuex'

  export default {
    data() {
      return {
        inviteMsg: {
          userHeadImg: '',
          name: '',
          comName: '',
        },
        params: {
          comId: 0,
          friendType: 1,
          ownerId: 0, // 要加入企业id或要成为好友用户的id
          ownerType: 1, // 0 个人 1 企业
          remarks: null,
        },
        comId: undefined,
        handleJoinInLoading: false,
      }
    },
    computed: {
      ...mapState({
        userInfo: state => state.user.userInfo,
      }),
    },
    created() {
      const { userHeadImg, name, comName, comId, userId } = this.$jsonPase(
        window.sessionStorage.getItem('inviteMember')
      )
      this.params.ownerId = comId
      this.comId = comId
      this.inviteMsg = {
        userHeadImg,
        name,
        comName,
      }
    },
    methods: {
      addPeople() {
        Api.applyFriend(this.params).then(res => {
          // if (this.$isWeiXin) {
          //   this.$router.push('/wxInner');
          // } else {
          //   this.$router.push('/notWxInner');
          // }
          this.$router.push('/notWxInner')
        })
      },
      changeAcount() {
        this.$router.push('/inviteMember')
      },
    },
  }
</script>
<style lang="less" scoped>
  .inviteExternalContact {
    padding-top: 30px;
    .headerImg {
      display: flex;
      margin: 0 auto;
      margin-bottom: 30px;
      width: 70px;
      height: 70px;
      border: 2px solid rgba(255, 255, 255, 1);
    }
    .title {
      height: 30px;
      font-size: 22px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(0, 0, 0, 1);
      line-height: 30px;
      text-align: center;
      margin-bottom: 10px;
    }
    .des {
      height: 22px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(0, 0, 0, 1);
      line-height: 22px;
      text-align: center;
      margin-bottom: 37px;
    }
    .content {
      padding: 0 24px;
      .label {
        margin-bottom: 8px;
        font-size: 14px;
      }
      .input {
        width: 327px;
        height: 46px;
        background: rgba(255, 255, 255, 1);
        border-radius: 4px;
        border: 1px solid rgba(226, 228, 234, 1);
        margin-bottom: 128px;
        display: flex;
        align-items: center;
      }
      .addPeople {
        width: 280px;
        height: 44px;
        background: rgba(22, 118, 255, 1);
        border-radius: 4px;
        margin: 0 auto;
        margin-bottom: 16px;
        font-size: 15px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(255, 255, 255, 1);
        line-height: 44px;
        text-align: center;
      }
      .addPeople-sub {
        height: 24px;
        font-size: 13px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(103, 114, 131, 1);
        line-height: 24px;
        text-align: center;
        margin-bottom: 16px;
      }
      .changeAccount {
        height: 24px;
        font-size: 15px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(22, 118, 255, 1);
        line-height: 24px;
        text-align: center;
      }
    }
    .admin-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      .van-button {
        width: 280px;
      }
      .van-button + .van-button {
        margin-top: 16px;
      }
    }
  }
</style>
